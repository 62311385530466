import React, { useState, useEffect, useCallback } from 'react';

import { Components } from 'sitedata';
import notify from 'notify';
import { getFirstError } from 'utils';
import { getSavedSearch, deleteSavedSearch as deleteSavedSearchRequest } from 'api/listings';
import IndexLayout from 'layouts';
import styled from '@emotion/styled';
import SavedSearchItem from './SavedSearchItem';

const SavedSearchesPage = () => {
  const [savesSearches, setSavedSearches] = useState<SavedSearch[]>([]);

  const deleteSavedSearch = async (id: string) => {
    try {
      await deleteSavedSearchRequest(id);
      setSavedSearches(items => items.filter(item => item.id !== id));
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  };

  const fetchSavedSearches = useCallback(async () => {
    try {
      const savedSearches = await getSavedSearch();

      setSavedSearches(savedSearches);
    } catch (err) {
      notify(getFirstError(err) || err.message);
    }
  }, []);

  useEffect(() => {
    fetchSavedSearches();
  }, [fetchSavedSearches]);

  return (
    <IndexLayout>
      <Components.Header hasBorder />
      <StyledSavedSearchesWrapper className="saved-searches-wrapper">
        <h1 className="title-saved-searches">Saved Searches</h1>
        {savesSearches.map(item => {
          return <SavedSearchItem {...item} key={item.id} onDeleteClick={deleteSavedSearch} />;
        })}
        {savesSearches.length === 0 && <div className="no-results">No saved searches</div>}
      </StyledSavedSearchesWrapper>
      <Components.Footer />
    </IndexLayout>
  );
};

const StyledSavedSearchesWrapper = styled.div`
  width: 100%;
  max-width: 1057px;
  margin: 50px auto 0;
  padding: 0 16px;

  .title-saved-searches {
    margin: 0 0 24px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }

  .no-results {
    height: 400px;
    display: grid;
    font-size: 24px;
    align-items: center;
    text-align: center;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin: 15px 0 0 0;
    max-width: initial;

    .title-saved-searches {
      margin: 0 0 12px;
    }

    .no-results {
      height: 200px;
    }
  }
`;

export default SavedSearchesPage;
