/* eslint-disable no-fallthrough */
import React, { useState } from 'react';
import pluralize from 'pluralize';

import useLayout from 'hooks/useLayout';
import { Button, Icon } from 'components';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

interface SavedSearchItemProps extends SavedSearch {
  url: string;
  params: Record<string, string>;
  onDeleteClick?: (id: string) => void;
}

const getTitleAddress = ({ city, street, state, postal_code }: any) => {
  let str = '';
  if (city) str += city;
  if (street) str += ` ${street}`;
  if (state || postal_code) {
    str += ',';
    if (state) str += ` ${state}`;
    if (postal_code) str += ` ${postal_code}`;
  }

  return str;
};

const SavedSearchItem: React.FC<SavedSearchItemProps> = ({ id, url, params, onDeleteClick }) => {
  const layout = useLayout();
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  const { total_results, listing_status, listing_type_hr } = params;
  const priceMin = Number(params.price_min) || 0;
  const priceMax = Number(params.price_max) || 0;
  const bedsMin = Number(params.beds_min) || 0;
  const bedsMax = Number(params.beds_max) || 0;
  const bathrooms = Number(params.bathrooms) || 0;
  const sqftMin = Number(params.sqft_min) || 0;
  const sqftMax = Number(params.sqft_max) || 0;
  const yearBuiltMin = params.year_built_min;
  const yearBuiltMax = params.year_built_max;
  const parkingSpaces = Number(params.parking_spaces) || 0;
  const listingStatus = Array.isArray(listing_status) ? listing_status.join(', ') : listing_status;
  const listingType = Array.isArray(listing_type_hr) ? listing_type_hr.join(', ') : listing_type_hr;

  const filters: string[] = [];
  if (priceMin && !priceMax) filters.push(`$${priceMin}+`);
  else if (priceMax) filters.push(`$${priceMin}-${priceMax}`);
  if (bedsMin < bedsMax) filters.push(`${bedsMin}-${bedsMax} beds`);
  else if (bedsMin) filters.push(`${bedsMin} ${pluralize('bed', bedsMin)}`);
  if (bathrooms) filters.push(`${bathrooms} ${pluralize('bathroom', bathrooms)}`);
  if (sqftMin && !sqftMax) filters.push(`${sqftMin}+ sqft`);
  else if (sqftMax) filters.push(`${sqftMin}-${sqftMax} sqft`);
  if (yearBuiltMin && !yearBuiltMax)
    filters.push(`built ${yearBuiltMin}-${new Date().getFullYear()}`);
  else if (yearBuiltMax) filters.push(`built ${yearBuiltMin}-${yearBuiltMax}`);
  if (parkingSpaces) filters.push(`${parkingSpaces} ${pluralize('parking space', parkingSpaces)}`);
  if (listingStatus) filters.push(`Status: ${listingStatus}`);
  if (listingType) filters.push(`Type: ${listingType}`);

  let filtersDisplay = filters;
  if (layout === 'mobile' && !showMoreFilters) filtersDisplay = filters.slice(0, 2);

  const getBoundsTitle = jsonString => {
    if (!jsonString) return;
    return 'Custom Boundary';
  };

  const title =
    params.search_string ||
    getTitleAddress(params) ||
    filters.slice(0, 3).join(', ') ||
    getBoundsTitle(params.bounds) ||
    `Search-${id}`;

  const internalUrl = `/search/?${url.split('?')[1]}`;

  return (
    <StyledSavedSearchItem className="saved-search-item">
      <Button
        className="saved-search-item__remove-button remove"
        simple
        onClick={() => onDeleteClick!(id)}>
        <Icon name="remove" size={24} className="saved-search-item__icon-remove" />
      </Button>
      <Icon name="search-saved" size={24} className="saved-search-item__icon-search" />
      <h4 className="saved-search-item__title">{title}</h4>
      {total_results && (
        <p className="saved-search-item__result-count">
          {total_results} {pluralize('home', +total_results)}
        </p>
      )}
      {filtersDisplay.length > 0 && (
        <div className="saved-search-item__filters-block">
          <span className="saved-search-item__filters-label">Filters: </span>
          {filtersDisplay.map(item => (
            <span className="saved-search-item__filter" key={item}>
              {item}
            </span>
          ))}
          {layout === 'mobile' && filters.length > 2 && (
            <button className="read-more" onClick={() => setShowMoreFilters(v => !v)}>
              {showMoreFilters ? 'less' : 'more'}
            </button>
          )}
        </div>
      )}
      <Link to={internalUrl}>
        <Button className="saved-search-item__button" primary>
          See results
        </Button>
      </Link>
    </StyledSavedSearchItem>
  );
};

const StyledSavedSearchItem = styled.div`
  background: ${props => props.theme.colors.cementGray};
  margin: 0 0 16px;
  padding: 16px 48px;
  position: relative;
  &:last-of-type {
    margin: 0;
  }
  .saved-search-item {
    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 8px;
      text-transform: capitalize;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    &__icon-search {
      position: absolute;
      top: 16px;
      left: 16px;
      flex-shrink: 0;
    }
    &__result-count {
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.darkCementGray};
      margin: 0 0 8px;
    }
    &__filters-block {
      margin: 0 0 16px;
      font-size: 14px;
      line-height: 24px;
      overflow: hidden;
    }
    &__filters-label {
      font-size: 14px;
      line-height: 24px;
      color: ${props => props.theme.colors.darkCementGray};
    }
    &__filter {
      font-size: 14px;
      line-height: 24px;
      margin: 0 10px 0 0;
      position: relative;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(50%, -50%);
        right: -5px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: ${props => props.theme.colors.darkGray};
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }
    &__button {
      &.button {
        width: 96px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
      }
    }
    &__remove-button {
      position: absolute;
      top: 16px;
      right: 16px;
      flex-shrink: 0;
      &.remove {
        border: 1px solid transparent;
        transition: ${props => props.theme.transitions.standart};
        &:hover {
          border: 1px solid ${props => props.theme.colors.primary};
        }
      }
    }
    &__icon-remove {
      fill: ${props => props.theme.colors.primary};
    }
  }
  .read-more {
    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
    display: inline;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    .saved-search-item {
      &__filter {
        display: block;
        white-space: normal;
        &:after {
          display: none;
        }
      }
      &__remove-button {
        &.remove {
          &:hover {
            border: 1px solid transparent;
          }
          &:active {
            border: 1px solid ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
`;

export default SavedSearchItem;
